import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout/";
import SEO from "../components/Seo";
import RecommendedPosts from "../components/RecommendedPosts";

import { PostHeader, PostTitle, PostDescription, PostDate, MainContent } from "../styles/base";

export default (props) => {
  const post = props.data.markdownRemark;
  const next = props.pageContext.next;
  const previous = props.pageContext.previous;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={`https://strapi.guru${post.frontmatter.image}`}
      />
      <PostHeader>
        <PostDate>
          {post.frontmatter.date} • {post.timeToRead} min read
        </PostDate>
        <PostTitle>{post.frontmatter.title}</PostTitle>
        <PostDescription>{post.frontmatter.description}</PostDescription>
      </PostHeader>
      <MainContent>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </MainContent>
      <RecommendedPosts next={next} previous={previous} />
    </Layout>
  );
};

export const query = graphql`
  query Post($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(locale: "en-us", formatString: "MM/DD/YYYY")
        # image
        description
        title
      }
      timeToRead
    }
  }
`;
